import { text as ThemeTextStyling } from "~/foundation/Theme/components/Text";
import { ColorTheme } from "~/foundation/Theme";

export const RichTextStyling = (theme: ColorTheme, rtl: boolean, columnCount = "") => ({
	"sx": {
		columnCount: columnCount ? [null, null, 2, columnCount] : "auto",
		columnGap: columnCount ? [null, null, "3rem"] : "normal",
		pe: columnCount === "1" ? [null, null, null, "3rem"] : "0",
		maxWidth: columnCount === "1" ? [null, null, null, "50%"] : "none",
		...ThemeTextStyling(rtl).sizes?.body,

		"&.editmode": {
			columnCount: "auto",
			columnGap: "normal",
		},

		'p, li, td': {
			...ThemeTextStyling(rtl).sizes?.body,
			mb: '1.775rem',
			'&:last-child': {
				mb: '0'
			},
		},
		'p.large-copy': {
			fontFamily: "body",
			fontWeight: "400",
			fontSize: ['md', null, null, 'lg'],
			lineHeight: ['7', null, null, '8'],
		},
		'blockquote': {
			fontSize: ["xl", null, null, "2xl"],
			lineHeight: ["2.25rem", null, null, "2.625rem"],
			letterSpacing: 'wide',
			textAlign: 'center',
			color: "inherit",
			my: "16",
			px: ["1.5rem", null, null, "2rem"]
		},
		'ul, ol': {
			ps: ['1.65rem', null, null, '1.75rem'],
			my: '1.75rem',
			'&:last-child': {
				mb: '0'
			},

			"li": {
				ps: ".625rem"
			},
		},
		"ul li": {
			mb: "0",
		},
		'a': {
			position: "relative",
			display: "inline",
			color: theme.colors.ribbon.linkColor,
			textDecoration: "underline",
			//...animations(rtl).underline,
			cursor: "pointer",
			'&[href^="mailto:"]': {
				ps: "6",
				'&:before': {
					position: "absolute",
					left: rtl ? "auto" : "0",
					right: rtl ? "0" : "auto",
					bottom: "0",
					top: "0",
					content: 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMS43MzI0MiIgeT0iMi43MzI0MiIgd2lkdGg9IjEyLjUzNTIiIGhlaWdodD0iMTAuNTM1MiIgcng9IjMuMjY3NTgiIHN0cm9rZT0iIzAwN0ZBRCIgc3Ryb2tlLXdpZHRoPSIxLjQ2NDg0Ii8+CjxwYXRoIGQ9Ik0zIDRMNi41ODU3OSA3LjU4NTc5QzcuMzY2ODQgOC4zNjY4NCA4LjYzMzE3IDguMzY2ODMgOS40MTQyMSA3LjU4NTc5TDEzIDQiIHN0cm9rZT0iIzAwN0ZBRCIgc3Ryb2tlLXdpZHRoPSIxLjQ2Ii8+Cjwvc3ZnPgo=")'
				},
				"&:after": {
					transform: rtl ? "translateX(150%)" : "translateX(-150%)",
				},
				"&:hover": {
					"&:after": {
						transform: "translateX(0)",
					}
				},
			},
		},
		"h1, h2": {
			fontFamily: "body",
			fontSize: ["xl", null, null, "3xl"],
			fontWeight: "300",
			letterSpacing: ['normal', null, null, 'wide'],
			lineHeight: ['2.25rem', null, null, '1.35'],
			mt: ["5.5rem", null, null, "8.925rem"],
			mb: ["1.625rem", null, null, "3.25rem"]
		},
		"h3, h4, h5, h6": {
			fontFamily: "body",
			fontSize: ["md", null, null, "lg"],
			fontWeight: "300",
			letterSpacing: [null, null, null, "wide"],
			lineHeight: ["1.75rem", null, null, "1.35"],
			mt: ["3rem", null, null, "3.5rem"],
			mb: ".5rem",
		},
		".scWebEditInput.scEnabledChrome": {
			// Special experience editor case for 3 cols
			columnCount: columnCount ? columnCount : "auto",
			columnGap: columnCount ? "3rem" : "normal",
		}
	}
})

export const RichTextStylingSmall = (theme: ColorTheme, rtl: boolean, columnCount = "") => ({
	"sx": {
		columnCount: columnCount ? [null, null, 2, columnCount] : "auto",
		columnGap: columnCount ? [null, null, "3rem"] : "normal",
		pe: columnCount === "1" ? [null, null, null, "3rem"] : "0",
		maxWidth: columnCount === "1" ? [null, null, null, "50%"] : "auto",
		fontFamily: 'body',
		fontWeight: '400',
		fontSize: 'xs',
		lineHeight: '1.25rem',
		letterSpacing: 'wider',

		"&.editmode": {
			columnCount: "auto",
			columnGap: "normal",
		},

		'p, li, td': {
			fontFamily: 'body',
			fontWeight: '400',
			fontSize: 'xs',
			lineHeight: '1.25rem',
			letterSpacing: 'wider',
			mb: '1rem',
			'&:last-child': {
				mb: '0'
			},
		},
		'p.large-copy': {
			...ThemeTextStyling(rtl).sizes?.body,
		},
		'ul, ol': {
			ps: '1.5rem',
			my: '1rem',
			'&:last-child': {
				mb: '0'
			},
		},
		'a': {
			position: "relative",
			display: "inline",
			color: theme.colors.ribbon.linkColor,
			//...animations(rtl).underline,
			textDecoration: "underline",
			cursor: "pointer",

			'&[href^="mailto:"]': {
				ps: "6",
				'&:before': {
					position: "absolute",
					left: rtl ? "auto" : "0",
					right: rtl ? "0" : "auto",
					bottom: "0",
					top: "0",
					content: 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3QgeD0iMS43MzI0MiIgeT0iMi43MzI0MiIgd2lkdGg9IjEyLjUzNTIiIGhlaWdodD0iMTAuNTM1MiIgcng9IjMuMjY3NTgiIHN0cm9rZT0iIzAwN0ZBRCIgc3Ryb2tlLXdpZHRoPSIxLjQ2NDg0Ii8+CjxwYXRoIGQ9Ik0zIDRMNi41ODU3OSA3LjU4NTc5QzcuMzY2ODQgOC4zNjY4NCA4LjYzMzE3IDguMzY2ODMgOS40MTQyMSA3LjU4NTc5TDEzIDQiIHN0cm9rZT0iIzAwN0ZBRCIgc3Ryb2tlLXdpZHRoPSIxLjQ2Ii8+Cjwvc3ZnPgo=")'
				},
				"&:after": {
					transform: rtl ? "translateX(150%)" : "translateX(-150%)",
				},
				"&:hover": {
					"&:after": {
						transform: "translateX(0)",
					}
				},
			},
		},
		".scWebEditInput.scEnabledChrome": {
			// Special experience editor case for 3 cols
			columnCount: columnCount ? columnCount : "auto",
			columnGap: columnCount ? "3rem" : "normal",
		}
	}
})